import moonwasp from './imgs/MoonWasp.svg';
import React from 'react';

function FlyingWasp (props) {
	return(

		<div className={"flying-wasp"} onClick={props.onClick}>
			<img src={moonwasp} />
		</div>
		
		)
}

export default FlyingWasp;
