function getTier (supply) {
    let s = Number(supply)

    if (s >= 600) {
        return 7
    } else if (s < 600 && s >= 500) {
        return 6
    } else if (s < 500 && s >= 400) {
        return 5
    } else if (s < 400 && s >= 300) {
        return 4
    } else if (s < 300 && s >= 100) {
        return 3
    } else if (s < 100 && s >= 10) {
        return 2
    } else {
        return 1
    }
}

export const TierDisplay = (props) => {
    let tier = getTier(props.supply)
    tier = "tier--" + tier.toString()

    return (
        <div className="tierContainer">
            <div className="tiers">
                <div className="tier tier--1">
                    <div className="tier__min">0</div>
                    <div className="tier__max">9</div>
                    <div className="tier__cost">0.01 xDAI</div>
                </div>
                <div className="tier tier--2">
                    <div className="tier__min">10</div>
                    <div className="tier__max">99</div>
                    <div className="tier__cost">0.10 xDAI</div>
                </div>
                <div className="tier tier--3">
                    <div className="tier__min">100</div>
                    <div className="tier__max">299</div>
                    <div className="tier__cost">0.50 xDAI</div>
                </div>
                <div className="tier tier--4">
                    <div className="tier__min">300</div>
                    <div className="tier__max">399</div>
                    <div className="tier__cost">1.25 xDAI</div>
                </div>
                <div className="tier tier--5">
                    <div className="tier__min">400</div>
                    <div className="tier__max">499</div>
                    <div className="tier__cost">2.50 xDAI</div>
                </div>
                <div className="tier tier--6">
                    <div className="tier__min">500</div>
                    <div className="tier__max">599</div>
                    <div className="tier__cost">5 xDAI</div>
                </div>
                <div className="tier tier--7">
                    <div className="tier__min">600</div>
                    <div className="tier__max">1000</div>
                    <div className="tier__cost">10 xDAI</div>
                </div>
            </div>
            <div className={"supplyTier " + tier}>
                <div className="currentSupply">
                    <div className="labelly">Current Supply</div>
                    <div>{props.supply} <span className="labelly">NEST</span></div>
                </div>
            </div>
        </div>
        )
}