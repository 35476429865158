import FlyingWasp from './flyingwasp.js';
import numeral from 'numeral'
import { TierDisplay } from './NestTools.js'
import Ds from './tools/DataScience.js'

const CONNECT_TEXT = 'Connect';
const CONNECTED_TEXT = 'Connected';

let ds = new Ds()

function poi (addy) {
	if (addy == "0xd8e59bdacd4978d6fad9887e45145421414d8a03") return "MOONWASP-WXDAI LP"
		if (addy == "0xf9d1326570d3227e7f7d2749b1c3982366ca731a") return "MOONWASP-HNY LP"
			if (addy == "0xf09786d1e1bda9966d7166957c41bd8a66fde7cf") return "MOONWASP-xCOMB LP"
				if (addy == "0x1333e0db281206cdb70aafb1d748a3fccb3e4798") return "MOONWASP-TEQUILA LP"
					if (addy == "0xAb23368c8fF6e518AE4a114cc10d9a1aC29f9667".toLowerCase()) return "Tequila Chef"
	if (addy == "0x8de3c3891268502f77db7e876d727257dec0f852") return "Maxus Dev"
		if (addy == "0xecf0545684a06a4ea7b9c2fb1b6c08f50436e9db") return "Maxus DeFi Wallet"
		return addy
}

export const TheNest = (props) => {
	console.log(ds.merge(props.nestHolders, props.waspHolders, "address"))
	let merged = ds.merge(props.nestHolders, props.waspHolders, "address") ? ds.merge(props.nestHolders, props.waspHolders, "address", {"nestBal":"0"}) : []
	return (

	

    <div id="theNest" className={"Nest " + "Nest__isOpen--" + props.nestState + " nlvl-" + props.nestLvl}>

      <FlyingWasp />
      <FlyingWasp />

      <div className={"NestQuest"}>
        <img className="nestImg" src={props.nestLogo} />
      </div>
      <h3>The Cost to Mint NEST increases as the supply increases:</h3>
      <TierDisplay supply={numeral(props.totalNESTSupply / 10 ** 18).format("0,0.00")} /> 
      

      <button className={"btn" + props.nestLvl} onClick={props.onboardMM}>{props.mmBtnText}</button>

     
      <p className="lvl2only">Your NEST balance: <span>{numeral(props.nestBalance / 10 ** 18).format("0,0.00")}</span> NEST</p>

 
      <p className="lvl2only">Your cost to claim: <span>{numeral((props.nestPrice / 10 ** 18) * (props.nestAccum / 10 ** 18) / 10).format("0,0.0000")}</span> xDai</p>
      <p className="lvl2only">The cost per NEST when claiming is 10% of the current cost of a new user's first NEST.</p>

      <table>
      <tr><th>Address</th><th>Nest</th><th>Wasp</th></tr>
      {merged.map(x => <tr><td>{poi(x.address)}</td><td>{numeral(x.nestBal / 10 ** 18).format("0.0000")}</td><td>{numeral(x.waspBal / 10 ** 9).format("0")}</td></tr>)}
      </table>

    

    </div>
  

  );
}

export class Nest {
	constructor (tract, addy) {
		this.nestContract = tract
		this.ADDY_NEST = addy
	}
  goMintInitialNest (callback = () => {})  {
    this.nestContract.methods.getNESTPrice().call({}, (error, _nestPrice) => {
      this.nestContract.methods.mintInitialNEST()
        .send(
          {to: this.ADDY_NEST,
          from: window.ethereum.selectedAddress,
          value: _nestPrice},
          (error, taken) => {
            callback()
          })


    })
  }
  goClaimNest (nestAccum, callback = () => {})  {
    this.nestContract.methods.getNESTPrice().call({}, (error, _nestPrice) => {
              this.nestContract.methods.claim()
                .send(
                  {to: this.ADDY_NEST,
                  from: window.ethereum.selectedAddress,
                  value: Math.ceil((_nestPrice / 10 ** 18) * (nestAccum ) / 9)},
                  (error, taken) => {
                    callback()
                  })


            })
  }
  getBalance (callback = () => {}) {
    this.nestContract.methods.balanceOf(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
        .then(
          (bal) => {

            console.log(bal)
            callback(bal)
          })
  }
}



