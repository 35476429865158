class Ds {
	merge(a, b, on, dummy) {

		//console.log(typeof a)
		if (typeof a !== 'object') return false
		if (typeof b !== 'object') return false
		if (typeof a[0] === 'undefined') return false
			if (typeof b[0] === 'undefined') return false
		if (typeof a[0][on] === 'undefined') return false
		if (typeof b[0][on] === 'undefined') return false
		console.log(a.length)
		console.log(b.length)
		let ax = (a.length > b.length) ? a : b
		let bx = (a.length > b.length) ? b : a
		let result = ax.map(aRow => {
		
		  let bMatch = bx.find(bRow => aRow[on] === bRow[on]) 
		  console.log(typeof bMatch)
		  if (typeof bMatch !== 'undefined') {
		  	return {...aRow, ...bMatch}
		  } else {
		  	console.log("DUMMY")
		  	return {...aRow, ...dummy}
		  }
		  
		  
		});
		

		return result
	}
}

export default Ds
