export function chainMap (id) {
	  if (id === "0x89") return "Matic"
	  if (id === "0xfa") return "Fantom"
	  if (id === "0x38") return "BSC"
	  if (id === "0x1") return "Ethereum"
	  if (id === "0x64") return "xDai"
	  return null
}


export const explorers = {
	"xDai": {
		"name": "blockscout",
		"baseUrl": "https://blockscout.com/xdai",
		"api": "https://blockscout.com/xdai/mainnet/api",
		"tokenHolders": "?module=token&action=getTokenHolders&contractaddress=",
		"more": "&page=0&offset=20"
	}
}

export const fetchy = (address, explorer, callback = () => {}) => {
	fetch(explorer.api + explorer.tokenHolders + address + explorer.more)
	  .then(response => response.json())
	  .then(data => {
	  	callback(data)
	  	});
}
